export default [
  {
    name: "UserProfile",
    path: "/user-profile",
    component: () => import("@/views/UserProfile"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
      title: "Informações do usuário",
    },
  },
  {
    name: "Company",
    path: "/company",
    component: () => import("@/views/Company"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "CompanyGroup",
    path: "/company-group",
    component: () => import("@/views/CompanyGroup"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Plant",
    path: "/plant",
    component: () => import("@/views/Plant"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "CostCenterClassificationType",
    path: "/cost-center-classification-type",
    component: () => import("@/views/CostCenterClassificationType"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "CostCenterClassification",
    path: "/cost-center-classification",
    component: () => import("@/views/CostCenterClassification"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "CostCenterType",
    path: "/cost-center-type",
    component: () => import("@/views/CostCenterType"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "CostCenter",
    path: "/cost-center",
    component: () => import("@/views/CostCenter"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "AccountClassificationType",
    path: "/account-classification-type",
    component: () => import("@/views/AccountClassificationType"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "AccountClassification",
    path: "/account-classification",
    component: () => import("@/views/AccountClassification"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "ChartAccountVersion",
    path: "/chart-account-version",
    component: () => import("@/views/ChartAccountVersion"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "ChartAccount",
    path: "/chart-account",
    component: () => import("@/views/ChartAccount"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "UnitMeasurementType",
    path: "/unit-measurement-type",
    component: () => import("@/views/UnitMeasurementType"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "UnitMeasurement",
    path: "/unit-measurement",
    component: () => import("@/views/UnitMeasurement"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Currency",
    path: "/currency",
    component: () => import("@/views/Currency"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "CurrencyPrice",
    path: "/currency-price",
    component: () => import("@/views/CurrencyPrice"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "MarketIndex",
    path: "/market-index",
    component: () => import("@/views/MarketIndex"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "MarketIndexPrice",
    path: "/market-index-price",
    component: () => import("@/views/MarketIndexPrice"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Frequency",
    path: "/frequency",
    component: () => import("@/views/Frequency"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "PaymentConditionType",
    path: "/payment-condition-type",
    component: () => import("@/views/PaymentConditionType"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "PaymentCondition",
    path: "/payment-condition",
    component: () => import("@/views/PaymentCondition"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "PaymentMethod",
    path: "/payment-method",
    component: () => import("@/views/PaymentMethod"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "VendorType",
    path: "/vendor-type",
    component: () => import("@/views/VendorType"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  // {
  //     name: 'Vendor',
  //     path: '/vendor',
  //     component: () =>
  //         import('@/views/Vendor'),
  //         meta: {
  //             requireAuth: true,
  //             redirectHomeIfLogged: false
  //         }
  // },
  {
    name: "VendorAddress",
    path: "/vendor-address",
    component: () => import("@/views/VendorAddress"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "VendorRegister",
    path: "/vendor",
    component: () => import("@/views/VendorRegister"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "CustomerRegister",
    path: "/customer",
    component: () => import("@/views/CustomerRegister"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "PersonRegister",
    path: "/person",
    component: () => import("@/views/PersonRegister"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "LegalPersonRegister",
    path: "/legal-person",
    component: () => import("@/views/LegalPersonRegister"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "GovernmentRegister",
    path: "/government",
    component: () => import("@/views/GovernmentRegister"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "CustomerType",
    path: "/customer-type",
    component: () => import("@/views/CustomerType"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "CustomerGroupType",
    path: "/customer-group-type",
    component: () => import("@/views/CustomerGroupType"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "CustomerGroup",
    path: "/customer-group",
    component: () => import("@/views/CustomerGroup"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "CustomerGroupRelation",
    path: "/customer-group-relation",
    component: () => import("@/views/CustomerGroupRelation"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "CustomerParam",
    path: "/customer-param",
    component: () => import("@/views/CustomerParam"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "CustomerParamValue",
    path: "/customer-param-value",
    component: () => import("@/views/CustomerParamValue"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  // {
  //     name: 'Person',
  //     path: '/person',
  //     component: () =>
  //         import('@/views/Person'),
  //         meta: {
  //             requireAuth: true,
  //             redirectHomeIfLogged: false
  //         }
  // },
  // {
  //     name: 'LegalPerson',
  //     path: '/legal-person',
  //     component: () =>
  //         import('@/views/LegalPerson'),
  //         meta: {
  //             requireAuth: true,
  //             redirectHomeIfLogged: false
  //         }
  // },
  // {
  //     name: 'Customer',
  //     path: '/customer',
  //     component: () =>
  //         import('@/views/Customer'),
  //         meta: {
  //             requireAuth: true,
  //             redirectHomeIfLogged: false
  //         }
  // },
  {
    name: "AddressType",
    path: "/address-type",
    component: () => import("@/views/AddressType"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Address",
    path: "/address",
    component: () => import("@/views/Address"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "CustomerAddress",
    path: "/customer-address",
    component: () => import("@/views/CustomerAddress"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "ProductRegister",
    path: "/product",
    component: () => import("@/views/ProductRegister"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "ProductType",
    path: "/product-type",
    component: () => import("@/views/ProductType"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "ProductOrigin",
    path: "/product-origin",
    component: () => import("@/views/ProductOrigin"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "ProductFamily",
    path: "/product-family",
    component: () => import("@/views/ProductFamily"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "ProductClass",
    path: "/product-class",
    component: () => import("@/views/ProductClass"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "ProductLine",
    path: "/product-line",
    component: () => import("@/views/ProductLine"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "ProductBrand",
    path: "/product-brand",
    component: () => import("@/views/ProductBrand"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "ProductCategory",
    path: "/product-category",
    component: () => import("@/views/ProductCategory"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "ProductParam",
    path: "/product-param",
    component: () => import("@/views/ProductParam"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "ProductParamValue",
    path: "/product-param-value",
    component: () => import("@/views/ProductParamValue"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  // {
  //     name: 'Product',
  //     path: '/product',
  //     component: () =>
  //         import('@/views/Product'),
  //         meta: {
  //             requireAuth: true,
  //             redirectHomeIfLogged: false
  //         }
  // },
  {
    name: "Bom",
    path: "/bom",
    component: () => import("@/views/Bom"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "BomTree",
    path: "/bom-tree",
    component: () => import("@/views/BomTree"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Role",
    path: "/role",
    component: () => import("@/views/Role"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "Employee",
    path: "/employee",
    component: () => import("@/views/Employee"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "EmployeeType",
    path: "/employee-type",
    component: () => import("@/views/EmployeeType"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "HrClassification",
    path: "/hr-classification",
    component: () => import("@/views/HrClassification"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
];
