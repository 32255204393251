"use strict";

import en from "vuetify/es5/locale/en";

export default {
  ...en,
  export: "Export sheet",
  import: "Import sheet",
  searchLabel: "Search",
  reload: "Reload",
  massDeleteBtn: "Delete selected items",
  crudCreateBtn: "Create",
  crudDeleteBtn: "Delete",
  crudUpdateBtn: "Edit",
  editingTableSnack: "Editing table line",
  cancelTableSnack: "Edition canceled",
  saveTableSnack: "Edition saved",
  toastError: "Oh...!",
  toastSuccess: "Success!",
  deleteSure: "Are you sure that you want to delete this item?",
  client: {
    title: "Clients",
    dialog: {
      create: "Create client",
      delete: "Delete client",
      massDelete: "Are you sure that you want to delete these clients?",
      update: "Update client",
    },
  },
  countryCode: "Country code",

};
