import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import "./plugins/axios";
import "./plugins/vee";
import "./plugins/vuetoastification";
import configs from "@/configs";
import setApiRoutes from "./helpers/setApiRoutes";

import { getPermissions, filters, errorFunction } from "@/utils";
Vue.filter("dateFilter", filters.dateFilter);
Vue.filter("floatFilter", filters.floatFilter);
Vue.filter("cpfCnpjFilter", filters.cpfCnpjFilter);
Vue.filter("zipCodeFilter", filters.zipCodeFilter);

Vue.prototype.$fnError = errorFunction;
Vue.prototype.$getPermissions = getPermissions;

Vue.config.productionTip = false;

const { system, env } = configs;
setApiRoutes(Vue, system, env);

Vue.prototype.$vuetify = vuetify;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
